import { NewsFeed } from '@eo-storefronts/eo-core'
import { CardContent, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  newsFeed: NewsFeed,
}

const NewsCardContent = ({ newsFeed }: Props) => (
  <CardContent sx={{
    p: 0,
    pt: 2
  }}>
    <Typography variant='body2'>{newsFeed.date}</Typography>
    <Title variant='h5'>{newsFeed.title}</Title>
  </CardContent>
)

export default NewsCardContent
