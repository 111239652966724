import CardMedia from '~/src/components/mui-wrappers/CardMedia'
import Card from '~/src/components/mui-wrappers/Card'
import { NewsFeed } from '@eo-storefronts/eo-core'
import NewsCardContent from '~/src/components/news/NewsCardContent'
import { useSetEoState } from '~/src/hooks/useEoState'
import { SELECTED_NEWS_STATE } from '~/src/stores/news'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import RoutesEnum from '~/src/router/enums/routes.enum'

interface Props {
  newsFeed: NewsFeed,
}

const NewsCard = ({ newsFeed }: Props) => {
  const setSelectedNews = useSetEoState(SELECTED_NEWS_STATE)
  const { resolve } = useFirmPathResolver()
  const { push } = useRouterPush()

  const handleOnClick = () => {
    setSelectedNews(newsFeed)

    push(resolve(`${RoutesEnum.NEWS}/${newsFeed.id}/details`))
  }

  return (
    <Card
      variant='outlined'
      sx={{
        height: '100%',
        backgroundColor: 'inherit',
        border: 'none',
        color: 'inherit',
        p: 0,
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      onClick={handleOnClick}
    >
      <CardMedia
        src={newsFeed.image}
        sx={{
          borderRadius: 4,
          aspectRatio: '16 / 9'
        }}
      />
      <NewsCardContent newsFeed={newsFeed} />
    </Card>
  )
}


export default NewsCard
