import useFetchNewsFeed from '~/src/hooks/news/useFetchNewsFeed'
import { useEffect } from 'react'
import InPageLoader from '~/src/components/loader/InPageLoader'
import GridList from '~/src/components/mui-wrappers/grid-list/GridList'
import GridListItem from '~/src/components/mui-wrappers/grid-list/GridListItem'
import { NewsFeed } from '@eo-storefronts/eo-core'
import NewsCard from '~/src/components/news/NewsCard'
import NoNews from '~/src/components/news/NoNews'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { NEWS_STATE } from '~/src/stores/news'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const NewsContainer = () => {
  const { isLoading, fetch } = useFetchNewsFeed()
  const news = useEoValue(NEWS_STATE)
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const theme = useCustomMuiTheme()

  useEffect(() => {
    void fetch()
  }, [])

  if (isLoading) {
    return <InPageLoader />
  }

  if (!news.length) {
    return <NoNews />
  }

  return (
    <GridList
      spacing={isMobile ? 1 : 4}
      columns={12}
      sx={{
        p: 0,
        py: 4,
        [theme.breakpoints.down('md')]: {
          px: 2
        }
      }}
    >
      {news.map((newsFeed: NewsFeed) => (
        <GridListItem
          key={newsFeed.id}
          xl={3}
          lg={4}
          md={4}
          sm={6}
          xs={12}
        >
          <NewsCard newsFeed={newsFeed} />
        </GridListItem>
      ))}
    </GridList>
  )
}

export default NewsContainer

