import { useState } from 'react'
import { HttpError, NewsFeed } from '@eo-storefronts/eo-core'
import { fetchNewsFeed } from '~/src/services/FirmService'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { NEWS_STATE } from '~/src/stores/news'
import { BRAND_STATE } from '~/src/stores/brand'

interface ReturnType {
  isLoading: boolean,
  error: HttpError | unknown | undefined,
  data: NewsFeed[],

  fetch(setState?: boolean): Promise<NewsFeed[] | undefined>,
}

const useFetchNewsFeed = (): ReturnType => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ data, setData ] = useState<NewsFeed[]>([])
  const [ error, setError ] = useState<HttpError | unknown | undefined>(undefined)
  const setNewsState = useSetEoState(NEWS_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const brand = useEoValue(BRAND_STATE)

  const fetch = async (setState = true): Promise<NewsFeed[] | undefined> => {
    if (!firm && !brand) {
      return
    }

    setIsLoading(true)
    setData([])
    setError(undefined)

    try {
      let data: NewsFeed[] = []

      if (firm) {
        data = await fetchNewsFeed(firm.id)
      } else if (brand) {
        data = await fetchNewsFeed(brand.id)
      }

      setData(data)
      setState && setNewsState(data)

      return data
    } catch (e: HttpError | unknown) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, error, data, fetch }
}

export default useFetchNewsFeed
