import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesNewsServiceInterface from '~/src/pages/news/styles/StylesNewsServiceInterface'
import getNewsTemplateService from '~/src/pages/news/styles/GetNewsTemplateService'

const useGetNewsTemplate = (): StylesNewsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('news')

  useLayout('news')

  return getNewsTemplateService(pageStyle, muiTheme)
}

export default useGetNewsTemplate
