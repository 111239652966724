import { Box } from '@mui/material'
import PageHeader from '~/src/components/marketplace/utils/PageHeader'
import GoBackToTheShopButton from '~/src/components/navigation/GoBackToTheShopButton'
import NewsContainer from '~/src/components/news/NewsContainer'
import useGetNewsTemplate from '~/src/pages/news/styles/useGetNewsTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LAYOUT_STATE } from '~/src/stores/layout'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const News = () => {
  const pageName: keyof typeof RoutesEnum = 'NEWS'

  const { isMobile } = useEoValue(LAYOUT_STATE)
  const { t } = useTranslations()

  useGetNewsTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>
      { !isMobile && (
        <Box sx={{
          my: 2
        }}>
          <GoBackToTheShopButton />
        </Box>
      )}
      <PageHeader title={t('news.title')} />
      <NewsContainer />
    </SlideFromRight>
  )
}

export default News
