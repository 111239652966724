import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesNewsServiceInterface from '~/src/pages/news/styles/StylesNewsServiceInterface'
import StylesNewsServiceStyleTwo from '~/src/pages/news/styles/StylesNewsServiceStyleTwo'
import StylesNewsServiceStyleThree from '~/src/pages/news/styles/StylesNewsServiceStyleThree'
import StylesNewsServiceStyleOne from '~/src/pages/news/styles/StylesNewsServiceStyleOne'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getNewsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesNewsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesNewsServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesNewsServiceStyleThree(muiTheme)
    default:
      return new StylesNewsServiceStyleOne(muiTheme)
  }
}

export default getNewsTemplateService
