import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesNewsServiceInterface from '~/src/pages/news/styles/StylesNewsServiceInterface'

export default class StylesNewsServiceStyleTwo extends TemplateServiceStyleBase implements StylesNewsServiceInterface {
  public getPageSx(): SxProps {
    return {}
  }

  getGoBackButtonSx(): SxProps {
    return {}
  }

  getGridListSx(): SxProps {
    return {}
  }

  getLocationHeaderSx(): SxProps {
    return {}
  }

  getTitleSx(): SxProps {
    return {}
  }

  getLoadingSpinnerSx(): SxProps {
    return {}
  }
}
